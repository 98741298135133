import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";

// Components
import SecondaryLanding from "../../components/layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import StrechedLinkCards from "../../components/card/stretched-link-cards";
import ContactInfo from "../../components/contact-info/contact-info";
import BestBanksDefault from "../../components/best-banks/best-banks-default";
import Navigation from "../../components/navigation/navigation";

const OnlineLoanBankingCalculators = () => {
  const imgData = useStaticQuery(graphql`
    {
      homeLoanCard: file(relativePath: { eq: "cards/thumbnail-home-loans-072924.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      firstTimeBuyerCard: file(relativePath: { eq: "cards/thumbnail-first-time-buyer-072924.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      refinanceCard: file(relativePath: { eq: "cards/thumbnail-refinancing-072924.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      helocCard: file(relativePath: { eq: "cards/thumbnail-heloc-072924.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      heloanCard: file(relativePath: { eq: "cards/thumbnail-heloan-072924.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      remodelingCard: file(relativePath: { eq: "cards/thumbnail-remodeling-072924.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      constructionCard: file(relativePath: { eq: "cards/thumbnail-construction-072924.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      landLoanCard: file(relativePath: { eq: "cards/thumbnail-land-loan-072924.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      cdSavingsCard: file(relativePath: { eq: "cards/thumbnail-cd-savings-072924.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);
  const title = "Online Bank Calculators for Loans & Finances",
    description =
      "Try various online banking, financial and loan calculators from WaFd Bank including HELOC calculators, home loan calculators, CD calculators and more.";
  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/online-loan-banking-calculators"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      active: true,
      title: "WaFd Bank Calculators"
    }
  ];

  const navData = {
    sectionClass: "bg-light py-3 mt-3",
    linksData: [
      {
        id: "home-loan-calculator-link",
        title: "Home Loan Calculator",
        href: "#home-loan-calculator-card",
        icon: "arrow-down"
      },
      {
        id: "heloc-calculator-link",
        href: "#heloc-calculator-card",
        title: "HELOC Calculator",
        icon: "arrow-down"
      },
      {
        id: "construction-calculator-link",
        href: "#construction-calculator-card",
        title: "Construction Loan Calculator",
        icon: "arrow-down"
      },
      {
        id: "first-time-buyer-calculator-link",
        href: "#first-time-buyer-calculator-card",
        title: "First Time Buyer Calculator",
        icon: "arrow-down"
      },
      {
        id: "heloan-calculator-link",
        href: "#heloan-calculator-card",
        title: "HELOAN Calculator",
        icon: "arrow-down"
      },
      {
        id: "land-loan-calculator-link",
        href: "#land-loan-calculator-card",
        title: "Land Loan Calculator",
        icon: "arrow-down"
      },
      {
        id: "refinance-calculator-link",
        href: "#refinance-calculator-card",
        title: "Refinance Calculator",
        icon: "arrow-down"
      },
      {
        id: "remodeling-calculator-link",
        href: "#remodeling-calculator-card",
        title: "Remodeling Calculator",
        icon: "arrow-down"
      },
      {
        id: "cd-savings-calculator-link",
        href: "#cd-savings-calculator-card",
        title: "CD Savings Calculator",
        icon: "arrow-down"
      }
    ]
  };

  const calculatorsCardData = {
    sectionId: "calculators-cards",
    sectionClass: "bg-white",
    cards: [
      {
        id: "home-loan-calculator-card",
        title: "Home Loan Calculator",
        titleClass: "text-success",
        text: "Play with the numbers and find a monthly payment you'd be comfortable with to set an early budget before you start looking for a house.",
        image: {
          imgVariable: imgData.homeLoanCard.childImageSharp.gatsbyImageData,
          altText: "Happy couple moving into a new home."
        },
        button: {
          id: "home-loan-calculator-card-cta",
          text: "Calculator",
          url: "/personal-banking/home-loans/home-mortgage#mortgage-calculator-section",
          class: "btn-primary btn-block stretched-link",
          containerClass: "mt-auto w-100",
          showIcon: false
        }
      },
      {
        id: "first-time-buyer-calculator-card",
        title: "First Time Buyer Calculator",
        titleClass: "text-success",
        text: "Ever wondered how much of a down payment you'd need to get a comfortable monthly payment? Play with the numbers and find out!",
        image: {
          imgVariable: imgData.firstTimeBuyerCard.childImageSharp.gatsbyImageData,
          altText: "Young couple having first meal in new house."
        },
        button: {
          id: "first-time-buyer-calculator-card-cta",
          text: "Calculator",
          url: "/personal-banking/home-loans/first-time-home-buyer-loans#mortgage-calculator-section",
          class: "btn-primary btn-block stretched-link",
          containerClass: "mt-auto w-100",
          showIcon: false
        }
      },
      {
        id: "refinance-calculator-card",
        title: "Refinance Calculator",
        titleClass: "text-success",
        text: "Whether you're getting cash out or lowering your rate, see what your payment might be and how a new mortgage could stack up to your current one.",
        image: {
          imgVariable: imgData.refinanceCard.childImageSharp.gatsbyImageData,
          altText: "Mature man in home kitchen sorting the home finances on laptop, talking on cell phone."
        },
        button: {
          id: "refinance-calculator-card-cta",
          text: "Calculator",
          url: "/personal-banking/home-loans/mortgage-refinance#mortgage-calculator-section",
          class: "btn-primary btn-block stretched-link",
          containerClass: "mt-auto w-100",
          showIcon: false
        }
      },
      {
        id: "heloc-calculator-card",
        title: "HELOC Calculator",
        titleClass: "text-success",
        text: "Borrow and pay back again and again. Find out how much money you could have access to and what your monthly payment might be.",
        image: {
          imgVariable: imgData.helocCard.childImageSharp.gatsbyImageData,
          altText: "Father and young son hugging in the kitchen."
        },
        button: {
          id: "heloc-calculator-card-cta",
          text: "Calculator",
          url: "/personal-banking/home-loans/heloc-home-equity-line-of-credit#mortgage-calculator-section",
          class: "btn-primary btn-block stretched-link",
          containerClass: "mt-auto w-100",
          showIcon: false
        }
      },
      {
        id: "heloan-calculator-card",
        title: "HELOAN Calculator",
        titleClass: "text-success",
        text: "Fixed payments? Yes! See what your monthly payment might be and how much you may be able to borrow.",
        image: {
          imgVariable: imgData.heloanCard.childImageSharp.gatsbyImageData,
          altText: "A couple laughing while sitting on the floor of their kitchen."
        },
        button: {
          id: "heloan-calculator-card-cta",
          text: "Calculator",
          url: "/personal-banking/home-loans/heloan-home-equity-loan#mortgage-calculator-section",
          class: "btn-primary btn-block stretched-link",
          containerClass: "mt-auto w-100",
          showIcon: false
        }
      },
      {
        id: "remodeling-calculator-card",
        title: "Remodeling Calculator",
        titleClass: "text-success",
        text: "Upgrade your home and get a loan based on the future value! Crunch some numbers and see what your monthly payment might be.",
        image: {
          imgVariable: imgData.remodelingCard.childImageSharp.gatsbyImageData,
          altText: "Couple laughing while painting a wall in their house."
        },
        button: {
          id: "remodeling-calculator-card-cta",
          text: "Calculator",
          url: "/personal-banking/home-loans/home-improvement-loans#mortgage-calculator-section",
          class: "btn-primary btn-block stretched-link",
          containerClass: "mt-auto w-100",
          showIcon: false
        }
      },
      {
        id: "construction-calculator-card",
        title: "Construction Loan Calculator",
        titleClass: "text-success",
        text: "Building a house can get complicated, but estimating your monthly payment doesn't have to be.",
        image: {
          imgVariable: imgData.constructionCard.childImageSharp.gatsbyImageData,
          altText: "Couple reviewing architectural plans with an architect."
        },
        button: {
          id: "construction-calculator-card-cta",
          text: "Calculator",
          url: "/personal-banking/home-loans/construction-loans#mortgage-calculator-section",
          class: "btn-primary btn-block stretched-link",
          containerClass: "mt-auto w-100",
          showIcon: false
        }
      },
      {
        id: "land-loan-calculator-card",
        title: "Land Loan Calculator",
        titleClass: "text-success",
        text: "Punch in land value, down payment, and interest rate to find out your estimated monthly payment.",
        image: {
          imgVariable: imgData.landLoanCard.childImageSharp.gatsbyImageData,
          altText: "Father hugging his kids in an open lot."
        },
        button: {
          id: "land-loan-calculator-card-cta",
          text: "Calculator",
          url: "/personal-banking/home-loans/land-loans#mortgage-calculator-section",
          class: "btn-primary btn-block stretched-link",
          containerClass: "mt-auto w-100",
          showIcon: false
        }
      },
      {
        id: "cd-savings-calculator-card",
        title: "CD Savings Calculator",
        titleClass: "text-success",
        text: "Find out how much your money can grow with a CD compared to a savings account.",
        image: {
          imgVariable: imgData.cdSavingsCard.childImageSharp.gatsbyImageData,
          altText: "Happy matured woman using WaFd Bank CD Savings calculator."
        },
        button: {
          id: "cd-savings-calculator-card-cta",
          text: "Calculator",
          url: "/personal-banking/savings-account/certificate-of-deposit#cd-rates-calculator",
          class: "btn-primary btn-block stretched-link",
          containerClass: "mt-auto w-100",
          showIcon: false
        }
      }
    ]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />
      <Navigation {...navData} />
      <section className="container pb-0">
        <h1>WaFd Bank Calculators</h1>
        <h3 className="pb-0">
          WaFd Bank calculators provide quick and accurate financial insights to help you manage your finances with
          ease. Whether you're planning a loan, budgeting, or saving, our tools offer the support you need to make
          informed decisions.
        </h3>
      </section>
      <StrechedLinkCards {...calculatorsCardData} />
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <StaticImage
                src="../../images/cards/thumbnail-cross-collateral-12272022.jpg"
                alt="Family moving day with dad holding a cardboard moving box and mom holding baby."
                placeholder="blurred"
                quality="100"
                loading="eager"
              />
            </div>
            <div className="col-md-6">
              <h2 className="text-success">Buy Your New Home Now, Sell Your Current Home Later</h2>
              <p>
                Need the equity in your current house to buy your new home? With our cross-collateral home loan, you
                won't need to make your offer to buy a new home contingent on selling your existing one.
              </p>
              <Link
                to="/personal-banking/home-loans/easy-move-cross-collateral"
                className="btn btn-primary w-100 w-sm-auto"
                id="easy-move-cross-collateral-page-cta"
              >
                Find Out How
              </Link>
            </div>
          </div>
        </div>
      </section>
      <ContactInfo bgClass="bg-white" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default OnlineLoanBankingCalculators;
